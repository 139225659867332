import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { CheckboxOption } from '../js/components/CheckboxOption';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "checkboxoption"
    }}>{`CheckboxOption`}</h1>
    <p><inlineCode parentName="p">{`import { CheckboxOption } from '@foodsby/nutrient'`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={CheckboxOption} mdxType="Props" />
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'<ThemeProvider theme={themeConWeb}>\n  <div style={{ backgroundColor: \'white\' }}>\n    <CheckboxOption\n      title=\"Remember me?\"\n      label=\"Stay signed in for a week.\"\n      id=\"remember\"\n      hint=\"You must have cookies enabled to use this option.\"\n    />\n  </div>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      CheckboxOption,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
    <div style={{
          backgroundColor: 'white'
        }}>
      <CheckboxOption title="Remember me?" label="Stay signed in for a week." id="remember" hint="You must have cookies enabled to use this option." mdxType="CheckboxOption" />
    </div>
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      