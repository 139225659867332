import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box } from './Box'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { space } from 'styled-system'

const Input = styled.input`
  &[type='checkbox'] {
    opacity: 0;
    height: auto;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border-width: 0;
    cursor: pointer;
    display: none;
  }

  &:hover,
  &:focus {
    & + label {
      color: ${props => props.theme.color.brandPrimary};
      & * {
        color: ${props => props.theme.color.brandPrimary};
      }
    }
    & + label:before {
      border-color: ${props => props.theme.color.brandPrimary};
    }
  }

  &:focus:invalid {
    border-color: ${props => props.theme.color.dangerLight};
  }

  &:checked {
    & + label:before {
      background: ${props => props.theme.color.brandPrimary};
      border-color: ${props => props.theme.color.brandPrimary};
      color: ${props => props.theme.color.white};
    }

    &:hover,
    &:focus {
      & + label:before {
        background: ${props => props.theme.color.brandPrimary};
      }
    }

    &:focus:invalid {
      & + label:before {
        background: ${props => props.theme.color.dangerLight};
      }
    }
  }

  &:focus {
    & + label {
      outline: none;
    }
  }
`

const Label = styled.label`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-basis: initial;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: nowrap;
  line-height: 16px;
  margin-bottom: 0;
  color: ${props => props.theme.primaryType};
  font-size: 16px;
  transition: color ${props => props.theme.transition};

  &:hover {
    &:before {
      box-shadow: ${props => props.theme.color.boxShadow};
    }
  }

  &:before {
    flex-shrink: 0;
    line-height: 12px;
    margin-right: 4px;
    padding: 0;
    content: '✓';
    background: transparent;
    color: transparent;
    border: 1px solid ${props => props.theme.color.greyShade5};
    width: 12px;
    height: 12px;
    display: inline-block;
    text-align: center;
    transition: box-shadow ${props => props.theme.transition},
      border ${props => props.theme.transition},
      background-color ${props => props.theme.transition};
  }

  &:hover,
  &:focus {
    color: ${props => props.theme.color.brandPrimary};

    &:before {
      border-color: ${props => props.theme.color.brandPrimary};
    }
  }
`

const Hint = styled.div`
  border-bottom: 1px solid transparent;
  color: ${props => props.theme.color.greyShade5};
  display: block;
  font-size: 12px;
  margin-left: 22px;
  margin-top: 4px;
  transition: border 0.5s;
`

const Title = styled.p`
  font-size: 12px;
  margin: 0 0 4px;
  color: ${props => props.theme.color.greyShade5};
`

export const CheckboxOption = ({
  id, // TODO: Generate a random id if one isn't passed in
  label,
  hint,
  pb = 3,
  title,
  ...rest
}) => (
  <Box pb={pb} className="checkbox" {...pick(rest, [...Object.keys(space)])}>
    {title && <Title>{title}</Title>}
    <Input type="checkbox" id={id} {...omit(rest, [...Object.keys(space)])} />
    <Label htmlFor={id}>{label}</Label>
    {hint && <Hint>{hint}</Hint>}
    {/* TODO: Add error/success */}
  </Box>
)

CheckboxOption.propTypes = {
  hint: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.node,
  pb: PropTypes.any,
  title: PropTypes.node
}
export default CheckboxOption
